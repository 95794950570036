import React from 'react'
import PropTypes from 'prop-types'
import { Box, Fade } from '@mui/material'

import CardSkeleton from './types/cardskeleton'
import TableSkeleton from './types/tableskeleton'

export type LoadingSkeletonProps = AT.IReactPropsDefaults & {
  type: 'card' | 'table'
  number: number
  loading: boolean
  childSxProp?: object
}

export default function LoadingSkeleton({
  sxProp,
  children,
  type,
  number,
  loading,
  childSxProp,
}: LoadingSkeletonProps) {
  function renderType(idx: number) {
    switch (type) {
      case 'card':
        return <CardSkeleton sxProp={childSxProp} key={`question${idx}`} />
      case 'table':
        return <TableSkeleton sxProp={childSxProp} key={`table${idx}`} />
      default:
        return <CardSkeleton sxProp={childSxProp} key={`question${idx}`} />
    }
  }

  function renderItem() {
    if (loading) {
      let row = []
      for (let i = 0; i < (number || 1); i++) {
        row.push(renderType(i))
      }
      return row
    }
    return (
      <Fade in={!loading} timeout={800}>
        <div>{children}</div>
      </Fade>
    )
  }

  return (
    <Box data-testid="skeleton" sx={sxProp}>
      {renderItem()}
    </Box>
  )
}
