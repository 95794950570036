import React, { ReactElement } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Card, CardContent, CardHeader } from '@mui/material'

export default function CardSkeleton({ sxProp }: AT.IReactPropsDefaults) {
  return (
    <Card
      sx={{
        width: '100%',
        minWidth: '300px',
        m: 4,
        ...sxProp,
      }}
    >
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={30}
            width="30%"
            style={{ marginBottom: 6 }}
            variant="text"
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            height={30}
            width="50%"
            style={{ marginBottom: 6 }}
            variant="text"
          />
        }
      />
      <CardContent>
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: 6 }}
          variant="text"
        />
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: 6 }}
          variant="text"
        />
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: 6 }}
          variant="text"
        />
      </CardContent>
    </Card>
  )
}
