import React, { ReactElement } from 'react'
import Skeleton from '@mui/material/Skeleton'

export default function TableSkeleton({ sxProp }: AT.IReactPropsDefaults) {
  return (
    <Skeleton
      variant="rectangular"
      sx={{ width: '100%', height: '500px', ...sxProp }}
    />
  )
}
